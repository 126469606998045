import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import { useLunr } from 'react-lunr';

import { Navigation } from '../utilities/components/navigation/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { ResultsView } from 'features/search/results/component/view';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import { routes } from 'utilities/routes';
import SEO from 'utilities/components/seo';

const Search = () => {
    const data = useStaticQuery(graphql`
        query {
            localSearchAccessories {
                index
                store
            }
            localSearchArticles {
                index
                store
            }
            localSearchDoors {
                index
                store
            }
        }
    `);
    const breadcrumbs = [routes.home, routes.wyszukiwarka];
    const [searchQuery, setSearchQuery] = useState(
        (typeof window !== 'undefined' && queryString.parse(window.location.search).fraza) || ''
    );
    const accessoriesResults = useLunr(
        searchQuery,
        data.localSearchAccessories.index,
        data.localSearchAccessories.store
    );
    const articlesResults = useLunr(searchQuery, data.localSearchArticles.index, data.localSearchArticles.store);
    const doorsResults = useLunr(searchQuery, data.localSearchDoors.index, data.localSearchDoors.store);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="search">
            <SEO noindex title="Wyszukiwarka | Barański Drzwi" canonicalLink={ setCanonicalLink() } />
            <Navigation searchQuery={ searchQuery } setSearchQuery={ setSearchQuery } />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <ResultsView results={ [...accessoriesResults, ...articlesResults, ...doorsResults] } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Search;
