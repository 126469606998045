import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { paths } from 'utilities/routes';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const ResultsView = ({ results }) => (
    <section className="results">
        {
            (results.length)
                ? (
                    results.map((currentValue, index) => (
                        <Link
                            className="results__container"
                            to={ `${paths[currentValue.url]}/${(currentValue.url === 'artykul') ? `${generateSlug(currentValue.headline)}-${currentValue.id}` : `${currentValue.id}`}` }
                            key={ index }
                        >
                            <Img
                                className={
                                    classnames('results__image', {
                                        'results__image--article': (currentValue.type === 'article')
                                    })
                                }
                                src={ `${process.env.GATSBY_URL}/${currentValue.image}` }
                                alt={ currentValue.imageAlt }
                            />
                            <div className="results__details">
                                {
                                    (currentValue.url === 'artykul')
                                        && (
                                            <p className="results__description">
                                                { currentValue.information1 }
                                                { currentValue.information2 ? ` | ${currentValue.information2}` : '' }
                                            </p>
                                        )
                                }
                                <h3 className="results__headline">
                                    { currentValue.headline }
                                </h3>
                                <p className="results__description">
                                    { currentValue.description }
                                </p>
                                {
                                    (currentValue.type === 'door')
                                        && (
                                            <p className="results__description">
                                                Cena od:
                                                { ' ' }
                                                { currentValue.information1 }
                                                { ' ' }
                                                zł
                                            </p>
                                        )
                                }
                            </div>
                        </Link>
                    ))
                )
                : (<h3 className="results__info">Brak wyników</h3>)
        }
    </section>
);

ResultsView.propTypes = {
    results: PropTypes.instanceOf(Array).isRequired
};